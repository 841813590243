import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./swiper.css";
import { IcLeftArrow, IcRightArrow } from "../../../constants/icons";
import { Pagination } from "swiper/modules";

import swiperSrc1 from "../../../assets/images/stick/stick-swiper1.png";
import swiperSrc2 from "../../../assets/images/stick/stick-swiper2.png";
import swiperSrc3 from "../../../assets/images/stick/stick-swiper3.png";
import swiperSrc4 from "../../../assets/images/stick/stick-swiper4.png";
import swiperSrc5 from "../../../assets/images/stick/stick-swiper5.png";
import swiperSrc6 from "../../../assets/images/stick/stick-swiper6.png";
import swiperSrc7 from "../../../assets/images/stick/stick-swiper7.png";
import useLanguage from "../../../hooks/useLanguage";

const SWIPER_LIST = [
    { title: "stick.second.swiper1", src: swiperSrc1 },
    { title: "stick.second.swiper2", src: swiperSrc2 },
    { title: "stick.second.swiper3", src: swiperSrc3 },
    { title: "stick.second.swiper4", src: swiperSrc4 },
    { title: "stick.second.swiper5", src: swiperSrc5 },
    { title: "stick.second.swiper6", src: swiperSrc6 },
    { title: "stick.second.swiper7", src: swiperSrc7 },
];

const SwiperImg = (props) => {
    const sliderRef = useRef(null);
    const { t } = useLanguage();

    const [totalIndex, setTotalIndex] = useState(0);
    const [curIndex, setCurIndex] = useState(0);

    const getTotalIndex = () => {
        setTotalIndex(sliderRef.current.swiper.snapGrid.length - 1);
    };

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        setCurIndex(sliderRef.current.swiper.snapIndex);
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        setCurIndex(sliderRef.current.swiper.snapIndex);
    }, []);

    useEffect(() => {
        getTotalIndex();
        window.addEventListener("resize", getTotalIndex);
        return () => {
            window.removeEventListener("resize", getTotalIndex);
        };
    }, []);

    return (
        <div className="swiper">
            <div className="swiper-btn">
                {curIndex === totalIndex ? (
                    <>
                        <IcRightArrow onClick={handlePrev} className="btn-rotate-180" />
                        <IcLeftArrow onClick={handleNext} className="btn-rotate-180" />
                    </>
                ) : curIndex === 0 ? (
                    <>
                        <IcLeftArrow onClick={handlePrev} />
                        <IcRightArrow onClick={handleNext} />
                    </>
                ) : (
                    <>
                        <IcRightArrow onClick={handlePrev} className="btn-rotate-180" />
                        <IcRightArrow onClick={handleNext} />
                    </>
                )}
            </div>
            <Swiper
                className="swiper-body"
                pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<div class="' + className + '">' + "</div>";
                    },
                }}
                modules={[Pagination]}
                ref={sliderRef}
                breakpoints={{
                    0: {
                        slidesPerView: "auto",
                    },
                    1439: {
                        slidesPerView: 5,
                    },
                }}
            >
                {SWIPER_LIST.map((li, idx) => (
                    <SwiperSlide className="swiper-item" key={idx}>
                        <div className="swiper-item-shadow" />
                        <img src={li.src} />
                        <p>{t(li.title)}</p>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className=""></div>

            <div className="swiper-btn-mobile">
                {curIndex === totalIndex ? (
                    <>
                        <IcRightArrow onClick={handlePrev} className="btn-rotate-180" />
                        <IcLeftArrow onClick={handleNext} className="btn-rotate-180" />
                    </>
                ) : curIndex === 0 ? (
                    <>
                        <IcLeftArrow onClick={handlePrev} />
                        <IcRightArrow onClick={handleNext} />
                    </>
                ) : (
                    <>
                        <IcRightArrow onClick={handlePrev} className="btn-rotate-180" />
                        <IcRightArrow onClick={handleNext} />
                    </>
                )}
            </div>
        </div>
    );
};

export default SwiperImg;
