import { useRef, useEffect, useState } from "react";
import { IcCardBg1, IcCardBg2, IcCardBg3, IcHdmi, IcStreaming, IcThirdArrow, IcWifi } from "../../../constants/icons";
import stickSrc from "../../../assets/images/stick/stick-img.png";
import cardBg1Src from "../../../assets/images/stick/stick-ic-card-bg1.png";
import cardImg1Src from "../../../assets/images/stick/stick-ic-card-img1.png";
import cardImg2Src from "../../../assets/images/stick/stick-ic-card-img2.png";
import cardImg3Src from "../../../assets/images/stick/stick-ic-card-img3.png";
import useLanguage from "../../../hooks/useLanguage";
import "./thirdpage.css";

const ThirdPage = () => {
    const { t, langState } = useLanguage();

    const thirdPageRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.3 }
        );
        observer.observe(thirdPageRef.current);
    }, []);

    return (
        <div className={`${show ? "show" : ""} stick-third-page`} ref={thirdPageRef}>
            <div className="stick-third-page-cards">
                <div className="stick-third-page-card">
                    <p>{t("stick.third.body1")}</p>
                    <img src={cardBg1Src} className="stick-third-page-card-bg" />
                    <img src={stickSrc} className="stick-third-page-card-img" />
                </div>
                <div className="stick-third-page-card second">
                    <p>{t("stick.third.body2")}</p>
                    <IcCardBg2 className="stick-third-page-card-bg-second" />
                    <img src={cardImg2Src} className="stick-third-page-card-img second" />
                </div>
                <div className="stick-third-page-card third">
                    <p>{t("stick.third.body3")}</p>
                    <IcCardBg3 className="stick-third-page-card-bg-third" />
                    <img src={cardImg3Src} className="stick-third-page-card-img third" />
                </div>
            </div>
            <div className="stick-third-page-bottom">
                <p>Plug & Play</p>
                <div className="stick-third-page-box-wrapper">
                    <div className="stick-third-page-box">
                        <IcHdmi />
                        <p>{t("stick.third.tab1")}</p>
                    </div>
                    <div className="stick-third-page-box">
                        <IcThirdArrow className="stick-third-page-box-arrow" />
                        <IcWifi />
                        <p>{t("stick.third.tab2")}</p>
                    </div>
                    <div className="stick-third-page-box">
                        <IcThirdArrow className="stick-third-page-box-arrow" />
                        <IcStreaming />
                        <p>{t("stick.third.tab3")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThirdPage;
