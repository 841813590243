import { useEffect, useRef, useState } from "react";
import {
    IconAdapter,
    IconArrowUp,
    IconHdmi,
    IconInstall1,
    IconInstall2,
    IconInstall3,
    IconInstall4,
    IconInstall5,
    IconInstall6,
    IconRemote,
    IconSignup1,
    IconSignup2,
    IconSignup3,
    IconStick,
    LogoBottom,
    LogoTop,
} from "../../constants/icons";
import "./stickguide.css";
import GuideBox from "./GuideBox";

const TAB_TITLE = ["Installation\nGuide", "Sign-up\nProcess"];
const INSTALL_GUIDE = [
    { img: <IconInstall1 />, text: "Connect Das Stick to TV's HDMI port." },
    { img: <IconInstall2 />, text: "Plug in the power adapter and insert it\n into the Das Stick." },
    { img: <IconInstall3 />, text: "Set TV input to HDMI source." },
    { img: <IconInstall4 />, text: "Access Settings on the screen." },
    { img: <IconInstall5 />, text: 'Connect to WiFi in "Network & Internet."' },
    { img: <IconInstall6 />, text: 'Launch "Daily Art Story" via "Apps."' },
];

const SIGN_UP = [
    { img: <IconSignup1 />, text: "Launch app, scan TV QR code with phone." },
    { img: <IconSignup2 />, text: "Sign-up using your Google account." },
    { img: <IconSignup3 />, text: "Access Daily Art Story services." },
];

const StickGuide = () => {
    const guideRef = useRef(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [showScroll, setShowScroll] = useState(false);

    const showScrollHandler = (e) => {
        if (guideRef.current.scrollTop > 0) {
            setShowScroll(true);
        } else {
            setShowScroll(false);
        }
    };

    useEffect(() => {
        guideRef.current.addEventListener("scroll", showScrollHandler);
        return () => {
            guideRef.current.removeEventListener("scroll", showScrollHandler);
        };
    }, []);

    return (
        <div className="stick-guide" ref={guideRef}>
            <div className="stick-guide-body">
                <div className="stick-guide-top">
                    <LogoTop />
                    <p className="stick-guide-top-subtitle">
                        Installation Guide <span>and</span>
                        {"\nSign-up Process"}
                    </p>
                </div>

                <div className="stick-guide-middle">
                    <div className="stick-guide-middle-title">
                        <p>Items included in the</p>
                        <p className="heavy">DAS Stick Box</p>
                    </div>
                    <div className="stick-guide-middle-body">
                        <div className="stick-guide-middle-item">
                            <IconStick />
                            <p>Das Stick</p>
                        </div>
                        <div className="stick-guide-middle-item">
                            <IconHdmi />
                            <p>{"HDMI\nExtension Cable"}</p>
                        </div>
                        <div className="stick-guide-middle-item">
                            <IconRemote />
                            <div>
                                <p>Voice Remote</p>
                                <p className="subtitle">{"(Requires 2 AAA batteries,\n purchased separately)"}</p>
                            </div>
                        </div>
                        <div className="stick-guide-middle-item">
                            <IconAdapter />
                            <p>Adapter</p>
                        </div>
                    </div>
                </div>
                <div className="stick-guide-tab">
                    <div className="stick-guide-tab-title">
                        {TAB_TITLE.map((title, idx) => (
                            <p
                                key={idx}
                                onClick={() => {
                                    setTabIndex(idx);
                                }}
                                className={idx === tabIndex ? "active" : ""}
                            >
                                {title}
                            </p>
                        ))}
                    </div>
                    <div>
                        {tabIndex === 0 ? (
                            <div className="stick-guide-install">
                                {INSTALL_GUIDE.map((step, idx) => (
                                    <GuideBox index={idx + 1} title={step.text} img={step.img} last={idx === INSTALL_GUIDE.length - 1} key={idx} />
                                ))}
                            </div>
                        ) : (
                            <div className="stick-guide-signup">
                                {SIGN_UP.map((step, idx) => (
                                    <GuideBox index={idx + 1} title={step.text} img={step.img} last={idx === SIGN_UP.length - 1} key={idx} />
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="stick-guide-middle-hr" />
                </div>
            </div>

            {showScroll && (
                <IconArrowUp
                    className="stick-guide-up"
                    onClick={() => {
                        guideRef.current.scrollTop = 0;
                    }}
                />
            )}
            <div className="stick-guide-bottom">
                <LogoBottom />
                <div className="stick-guide-bottom-support">
                    <p>Customer Support</p>
                    <p className="email">support@dasvers.com</p>
                </div>
            </div>
        </div>
    );
};

export default StickGuide;
