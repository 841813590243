import bgSrc from "../../../assets/images/ott/first-bg.png";
import bgMobileSrc from "../../../assets/images/ott/first-bg-mobile.png";
import useLanguage from "../../../hooks/useLanguage";
import "./firstpage.css";
import { join } from "../../../utils/join";
import { loginAtomState } from "../../../stores/atom";
import { useRecoilState } from "recoil";

const FirstPage = () => {
    const { t, langState } = useLanguage();
    const [loginState, setLoginState] = useRecoilState(loginAtomState);

    return (
        <div className="first-page">
            <div className="first-page-shadow" />
            <div className="first-page-shadow-center" />
            <div className="first-page-body">
                <p className={`first-page-title ${langState}`}>
                    {t("ott.first.title.title1")}
                    <span> {t("ott.first.title.title2")}</span>
                    {t("ott.first.title.title3")}
                </p>

                <p className={`first-page-title-mobile ${langState}`}>
                    {t("ott.first.title-mobile.title1")}
                    <span> {t("ott.first.title-mobile.title2")}</span>
                    <p className={`first-page-title-mobile ${langState}`}>{t("ott.first.title-mobile.title3")}</p>
                </p>

                <p className="first-page-subtitle">{t("ott.first.subtitle")}</p>
                <p className="first-page-subtitle-mobile">{t("ott.first.subtitle-mobile")}</p>

                <div className="first-page-green-wrapper">
                    <p>{t("ott.first.btn")}</p>
                </div>

                {loginState && (
                    <div
                        // className="first-page-btn-wrapper"
                        className="item-hidden"
                        onClick={() => {
                            join(langState);
                        }}
                    >
                        <button className="first-page-btn" />
                        <p>{t("ott.first.btn")}</p>
                    </div>
                )}
            </div>
            <img src={bgSrc} className="first-page-bg" />
            <img src={bgMobileSrc} className="first-page-bg-mobile" />
        </div>
    );
};

export default FirstPage;
