import { useEffect, useRef, useState } from "react";
import FirstPage from "../../components/Display/FirstPage/FirstPage";
import SecondPage from "../../components/Display/SecondPage/SecondPage";
import ThirdPage from "../../components/Display/ThirdPage/ThirdPage";
import FourthPage from "../../components/Display/FourthPage/FourthPage";
import FifthPage from "../../components/Display/FifthPage/FifthPage";
import LastPage from "../../components/Display/LastPage/LastPage";
import { useRecoilState } from "recoil";
import { positionAtomState } from "../../stores/atom";
import "./display.css";

function Display() {
    const [position, setPositionState] = useRecoilState(positionAtomState);

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, isStick: false, isDisplay: { ...prev.isDisplay, flag: true } };
        });
    }, []);

    return (
        <>
            <FirstPage />
            <SecondPage />
            <ThirdPage />
            <FourthPage />
            <FifthPage />
            <LastPage />
        </>
    );
}

export default Display;
