import { useEffect, useRef, useState } from "react";
import { IcFourthBox, IcFourthTv, IcTv } from "../../../constants/icons";
import useLanguage from "../../../hooks/useLanguage";
import videoSrc from "../../../assets/images/ott/video.mp4";
import "./fourthpage.css";
import { useScroll, motion, useTransform } from "framer-motion";
import { useRecoilState } from "recoil";
import { positionAtomState } from "../../../stores/atom";
import tvInBoxImg from "../../../assets/images/ott/tvInBox.png";

const FourthPage = () => {
    const tvRef = useRef(null);

    const { t, langState } = useLanguage();
    const [position, setPositionState] = useRecoilState(positionAtomState);
    const [show, setShow] = useState(false);

    const { scrollYProgress } = useScroll({
        target: tvRef,
        // offset: ["start end", "end end"],
    });

    // const width = useTransform(scrollYProgress, [0, 1], ["100px", "636px"]);
    const scale = useTransform(scrollYProgress, [0, 1], ["100%", "33%"]);
    const translateY = useTransform(scrollYProgress, [0, 1], ["0", "740px"]);
    const translateX = useTransform(scrollYProgress, [0, 1], ["0", "-8px"]);

    const getScroll = () => {
        if (window.scrollY > tvRef.current?.offsetTop) {
            setShow(true);
        } else {
            setShow(false);
        }
    };

    const getPosition = () => {
        setPositionState((prev) => {
            return { ...prev, START: tvRef.current.offsetTop };
        });
    };

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, START: tvRef.current.offsetTop };
        });
        window.addEventListener("resize", getPosition);
        window.addEventListener("scroll", getScroll);
        return () => {
            window.removeEventListener("resize", getPosition);
            window.removeEventListener("scroll", getScroll);
        };
    }, []);

    return (
        <div ref={tvRef}>
            <div className="scroll-wrapper">
                <motion.div
                    style={{
                        scale,
                        translateY,
                        translateX,
                    }}
                >
                    <IcTv className="fourth-page-tv" />
                    <video src={videoSrc} className={`fourth-tv ${show ? "show" : ""} web`} muted autoPlay loop playsInline></video>
                </motion.div>
            </div>
            <div className="fourth-page">
                <div className="fourth-page-top">
                    <p className={`fourth-page-title title ${langState}`}>{t("ott.fourth.title")}</p>
                    <p className="fourth-page-subtitle subtitle">{t("ott.fourth.subtitle")}</p>
                </div>
                <IcFourthTv className="fourth-page-void" />

                <IcFourthBox className="fourth-page-box" />
                <div className='fourth-page-bottom'>
                    <div>
                        <img src={tvInBoxImg} alt="Daily Art Story" />
                    </div>
                </div>
                {/* <div className={`fourth-page-bottom ${langState}`}>
                    <div>
                        <p className="fourth-page-bottom-title">{t("ott.fourth.body1.title")}</p>
                        <p className="fourth-page-bottom-subtitle">{t("ott.fourth.body1.subtitle")}</p>
                    </div>
                    <div className="fourth-page-bottom-border" />
                    <div>
                        <p className="fourth-page-bottom-title">{t("ott.fourth.body2.title")}</p>
                        <p className="fourth-page-bottom-subtitle">{t("ott.fourth.body2.subtitle")}</p>
                    </div>
                    <div className="fourth-page-bottom-border" />
                    <div>
                        <p className="fourth-page-bottom-title">{t("ott.fourth.body3.title")}</p>
                        <p className="fourth-page-bottom-subtitle">{t("ott.fourth.body3.subtitle")}</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default FourthPage;
