import StickGuide from "../../components/StickGuide/StickGuide";
import StickGuideMobile from "../../components/StickGuide/StickGuideMobile";
import { IconInsta, IconYoutube, IconTwitter } from "../../constants/icons";
import { INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from "../../constants/link";
import "./stickguidelayout.css";

const StickGuideLayout = () => {
    return (
        <div className="stick-guide-layout">
            <div className="stick-guide-left">
                <p>
                    {"Watch and admire art unlimitedly\n from "}
                    <span>Daily Art Story</span>
                </p>

                <div className="stick-guide-sns">
                    <IconTwitter
                        onClick={() => {
                            window.open(TWITTER_URL);
                        }}
                    />
                    <IconYoutube
                        onClick={() => {
                            window.open(YOUTUBE_URL);
                        }}
                    />
                    <IconInsta
                        onClick={() => {
                            window.open(INSTAGRAM_URL);
                        }}
                    />
                </div>
            </div>
            <StickGuide />
            <StickGuideMobile />
        </div>
    );
};

export default StickGuideLayout;
