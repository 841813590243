import { useRef, useEffect, useState } from "react";
import useLanguage from "../../../hooks/useLanguage";
import "./secondpage.css";
import SecondPageSwiper from "../Swiper/Swiper";

const SecondPage = () => {
    const { t, langState } = useLanguage();

    const secondPageRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.5 }
        );
        observer.observe(secondPageRef.current);
    }, []);

    return (
        <div className={`${show ? "show" : ""} second-page`} ref={secondPageRef}>
            <SecondPageSwiper />
            <div className="second-page-bg-shadow" />
            <div className="second-page-shadow" />
            <div className="second-page-body">
                <p className={`second-page-title title ${langState}`}>{t("ott.second.title")}</p>
                <p className={`second-page-title-mobile title ${langState}`}>{t("ott.second.title-mobile")}</p>
                <p className="second-page-subtitle subtitle">{t("ott.second.subtitle")}</p>
                <p className="second-page-subtitle-mobile subtitle">{t("ott.second.subtitle-mobile")}</p>
            </div>
            <div className="second-page-shadow-bottom" />
        </div>
    );
};

export default SecondPage;
