import useLanguage from "../../../hooks/useLanguage";
import fourthBgSrc from "../../../assets/images/display/display-fourth-bg.png";
import "./fourthpage.css";
import { useEffect, useRef } from "react";
import { positionAtomState } from "../../../stores/atom";
import { useRecoilState } from "recoil";

const FourthPage = () => {
    const fourthRef = useRef();
    const [position, setPositionState] = useRecoilState(positionAtomState);
    const { t } = useLanguage();

    const getPosition = () => {
        setPositionState((prev) => {
            return { ...prev, isDisplay: { ...prev.isDisplay, firstEnd: fourthRef.current.offsetTop - 1 } };
        });
    };

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, isDisplay: { ...prev.isDisplay, firstEnd: fourthRef.current.offsetTop - 1 } };
        });
        window.addEventListener("resize", getPosition);
        return () => {
            window.removeEventListener("resize", getPosition);
        };
    }, []);

    return (
        <div className="display-fourth" ref={fourthRef} style={{ backgroundImage: `url(${fourthBgSrc})` }}>
            <div className="display-fourth-left">
                <p className="display-title">{t("display.fourth.title")}</p>
                <p className="display-fourth-subtitle display-subtitle web">{t("display.fourth.subtitle")}</p>
                <p className="display-fourth-subtitle display-subtitle mobile">{t("display.fourth.subtitle-mobile")}</p>
            </div>
            <div className="display-fourth-hr" />
            <div className="display-fourth-right">
                <p className="display-fourth-right-label">Email</p>
                <p className="display-fourth-right-value">contact@dasvers.com</p>
            </div>
        </div>
    );
};

export default FourthPage;
