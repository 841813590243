import { useEffect, useRef } from "react";
import { positionAtomState } from "../../../stores/atom";
import { useRecoilState } from "recoil";
import fifthImg1 from "../../../assets/images/display/text/display-fifth-1.png";
import fifthImg1Mobile from "../../../assets/images/display/text/display-fifth-1-mobile.png";
import fifthImg2 from "../../../assets/images/display/text/display-fifth-2.png";
import fifthImg2Mobile from "../../../assets/images/display/text/display-fifth-2-mobile.png";
import fifthImg1Eng from "../../../assets/images/display/text/display-fifth-1-eng.png";
import fifthImg1MobileEng from "../../../assets/images/display/text/display-fifth-1-mobile-eng.png";
import fifthImg2Eng from "../../../assets/images/display/text/display-fifth-2-eng.png";
import fifthImg2MobileEng from "../../../assets/images/display/text/display-fifth-2-mobile-eng.png";
import "./fifthpage.css";
import useLanguage from "../../../hooks/useLanguage";

const FifthPage = () => {
    const fifthRef = useRef();
    const [position, setPositionState] = useRecoilState(positionAtomState);
    const { t, langState } = useLanguage();

    const getPosition = () => {
        setPositionState((prev) => {
            return { ...prev, isDisplay: { ...prev.isDisplay, secondStart: fifthRef.current.offsetTop - 1 } };
        });
    };

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, isDisplay: { ...prev.isDisplay, secondStart: fifthRef.current.offsetTop - 1 } };
        });
        window.addEventListener("resize", getPosition);
        return () => {
            window.removeEventListener("resize", getPosition);
        };
    }, []);

    return (
        <div className="display-fifth" ref={fifthRef}>
            <p className={`display-fifth-title display-title ${langState}`}>{t("display.fifth.title")}</p>
            {langState === "kor" ? (
                <>
                    <img src={fifthImg1} className="display-fifth-img1 web" alt="web-img" />
                    <img src={fifthImg1Mobile} className="display-fifth-img1 mobile" alt="mobile-img" />
                    <img src={fifthImg2} className="display-fifth-img2 web" alt="web-img" />
                    <img src={fifthImg2Mobile} className="display-fifth-img2 mobile" alt="mobile-img" />
                </>
            ) : (
                <>
                    <img src={fifthImg1Eng} className="display-fifth-img1 web" alt="web-img-eng" />
                    <img src={fifthImg1MobileEng} className="display-fifth-img1 mobile" alt="web-img-eng" />
                    <img src={fifthImg2Eng} className="display-fifth-img2 web" alt="web-img-eng" />
                    <img src={fifthImg2MobileEng} className="display-fifth-img2 mobile" alt="web-img-eng" />
                </>
            )}
        </div>
    );
};

export default FifthPage;
