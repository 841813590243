import React, { useState, useEffect } from "react";
import bg1Src from "../../../assets/images/ott/second-banner1.png";
import bg2Src from "../../../assets/images/ott/second-banner2.png";
import bg3Src from "../../../assets/images/ott/second-banner3.png";
import bg4Src from "../../../assets/images/ott/second-banner4.png";
import bg1SrcMobile from "../../../assets/images/ott/second-mobile-banner1.png";
import bg2SrcMobile from "../../../assets/images/ott/second-mobile-banner2.png";
import bg3SrcMobile from "../../../assets/images/ott/second-mobile-banner3.png";
import bg4SrcMobile from "../../../assets/images/ott/second-mobile-banner1.png";
import "./swiper.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";

const bgSrc = [bg1Src, bg2Src, bg3Src, bg4Src];
const bgSrcMobile = [bg1SrcMobile, bg2SrcMobile, bg3SrcMobile, bg4SrcMobile];

const SecondPageSwiper = (props) => {
    return (
        <>
            <div className="swiper-web">
                <Swiper
                    effect={"fade"}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[EffectFade, Autoplay]}
                    className="second-swiper"
                    loop={true}
                >
                    {bgSrc.map((item, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                <img src={item} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className="swiper-mobile">
                <Swiper
                    effect={"fade"}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[EffectFade, Autoplay]}
                    className="second-swiper"
                    loop={true}
                >
                    {bgSrcMobile.map((item, idx) => {
                        return (
                            <SwiperSlide key={idx}>
                                <img src={item} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </>
    );
};

export default SecondPageSwiper;
