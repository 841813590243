import useLanguage from "../../../hooks/useLanguage";
import fisrtImgSrc from "../../../assets/images/display/display-first-img.png";
import { DisplayFirstBg, DisplayFirstBgMobile } from "../../../constants/icons";
import "./firstpage.css";

const FirstPage = () => {
    const { t, langState } = useLanguage();

    return (
        <div className="display-first">
            <div className="display-first-top">
                <p className={`display-title display-first-title web ${langState}`}>
                    {t("display.first.title.title1")}
                    <span>{t("display.first.title.title2")}</span>
                    {t("display.first.title.title3")}
                    <span>{t("display.first.title.title4")}</span>
                    {t("display.first.title.title5")}
                </p>
                <p className={`display-title display-first-title mobile ${langState}`}>
                    {t("display.first.title-mobile.title1")}
                    <span>{t("display.first.title-mobile.title2")}</span>
                    {t("display.first.title-mobile.title3")}
                    <span>{t("display.first.title-mobile.title4")}</span>
                </p>
                <p className="display-subtitle web">{t("display.first.subtitle")}</p>
                <p className="display-subtitle mobile">{t("display.first.subtitle-mobile")}</p>
            </div>
            <div className="display-first-middle">
                <img src={fisrtImgSrc} />
                <DisplayFirstBgMobile className="display-first-bg mobile" />
            </div>

            <div className="display-first-bottom">
                <div>
                    <p className="display-first-bottom-title">{t("display.first.bottom.item1.title")}</p>
                    <p className="display-first-bottom-subtitle">{t("display.first.bottom.item1.subtitle")}</p>
                </div>
                <div className="display-first-bottom-hr" />
                <div>
                    <p className="display-first-bottom-title web">{t("display.first.bottom.item2.title")}</p>
                    <p className="display-first-bottom-title mobile">{t("display.first.bottom.item2.title_mobile")}</p>
                    <p className="display-first-bottom-subtitle">{t("display.first.bottom.item2.subtitle")}</p>
                </div>
                <div className="display-first-bottom-hr" />
                <div>
                    <p className="display-first-bottom-title web">{t("display.first.bottom.item3.title")}</p>
                    <p className="display-first-bottom-title mobile">{t("display.first.bottom.item3.title_mobile")}</p>
                    <p className="display-first-bottom-subtitle">{t("display.first.bottom.item3.subtitle")}</p>
                </div>
            </div>
            <DisplayFirstBg className="display-first-bg web" />
        </div>
    );
};

export default FirstPage;
