import { Link } from "react-router-dom";
import { IcInsta, IcTwitter, IcYoutube } from "../../constants/icons";
import { INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from "../../constants/link";
import useLanguage from "../../hooks/useLanguage";
import Button from "../OTT/Button/Button";
import footerImg from "../../assets/images/footerIcon.png";
import "./footer.css";

const Footer = () => {
    const { t } = useLanguage();
    return (
        <div className="footer">
            <div>
                <img src={footerImg} alt="footer-img" />
                <div className="footer-middle-summary">
                    <p>1 Paya Lebar Link #06-08, PLQ2, Paya Lebar Quarter, Singapore (408533)</p>
                    <div className="footer-bottom">
                        <div className="footer-bottom-info">
                            <span>contact@dasvers.com {"  |"}</span>
                            <span>
                                (+65) 6381 6390
                                {"  |"}
                            </span>
                            <Link className="footer-link" to="https://www.dasuniverse.io">
                                www.dasuniverse.io
                            </Link>
                        </div>
                    </div>
                </div>

                <span>© DAS UNIVERSE. All Rights Reserved.</span>
            </div>

            {/* <div className="footer-policy">
                <p>{t("footer.service")}</p>
                <p>{t("footer.privacy")}</p>
                <p>{t("footer.paid-service")}</p>
                <p>{t("footer.youth-protect")}</p>
            </div> */}
            {/* <div className="footer-bottom">
                <div className="footer-bottom-info">
                    <p>
                        {t("footer.dasverse")}
                        {" |"}
                    </p>
                    <p>
                        {t("footer.ceo")}
                        {" |"}
                    </p>
                    <p>
                        {t("footer.address")}
                        {" |"}
                    </p>
                    <p>
                        {t("footer.email")}
                        {" |"}
                    </p>
                    <p>{t("footer.phone")}</p>
                </div>
                <p>{t("footer.rights")}</p>
            </div>
            <div className="footer-bottom-sns">
                <IcTwitter
                    onClick={() => {
                        window.open(TWITTER_URL);
                    }}
                />
                <IcYoutube
                    onClick={() => {
                        window.open(YOUTUBE_URL);
                    }}
                />
                <IcInsta
                    onClick={() => {
                        window.open(INSTAGRAM_URL);
                    }}
                />
            </div> */}
        </div>
    );
};

export default Footer;
