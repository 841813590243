import { useEffect, useRef, useState } from "react";
import FifthPage from "../../components/Stick/FifthPage/FifthPage";
import FirstPage from "../../components/Stick/FirstPage/FirstPage";
import FourthPage from "../../components/Stick/FourthPage/FourthPage";
import LastPage from "../../components/Stick/LastPage/LastPage";
import SecondPage from "../../components/Stick/SecondPage/SecondPage";
import ThirdPage from "../../components/Stick/ThirdPage/ThirdPage";
import { IcFourthBgCircle } from "../../constants/icons";
import "./stick.css";
import { useRecoilState } from "recoil";
import { positionAtomState } from "../../stores/atom";

function Stick() {
    const [position, setPositionState] = useRecoilState(positionAtomState);

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, isStick: true, isDisplay: { flag: false } };
        });
    }, []);

    return (
        <>
            <FirstPage />
            <SecondPage />
            <ThirdPage />
            <FourthPage />
            {/* <FifthPage /> */}
            <LastPage />
        </>
    );
}

export default Stick;
