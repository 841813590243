import { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import FooterMobile from "../../components/Footer/FooterMobile";
import Header from "../../components/Header/Header";
import { IcTopArrow } from "../../constants/icons";
import { Outlet } from "react-router-dom";
import PriceModal from "../../components/OTT/Modals/PriceModal";
import "./layout.css";
import NavModal from "../../components/OTT/Modals/NavModal";
import { positionAtomState } from "../../stores/atom";
import { useRecoilState } from "recoil";

function Layout() {
    const appRef = useRef(null);
    const [priceModalOpen, setPriceModalOpen] = useState(false);
    const [navModalOpen, setNavModalOpen] = useState(false);
    const [yPosition, setYPosition] = useState(0);
    const [showScroll, setShowScroll] = useState(false);

    const [position, setPositionState] = useRecoilState(positionAtomState);

    const openPriceModal = () => {
        setPriceModalOpen(true);
    };
    const closePriceModal = () => {
        setPriceModalOpen(false);
    };
    const openNavModal = () => {
        setNavModalOpen(true);
    };
    const closeNavModal = () => {
        setNavModalOpen(false);
    };

    const showScrollHandler = (e) => {
        setYPosition(window.scrollY);
        if (window.scrollY > 0) {
            setShowScroll(true);
        } else {
            setShowScroll(false);
        }
    };

    useEffect(() => {
        if (priceModalOpen || navModalOpen) {
            appRef.current.style = `
            position: fixed;
            top: -${yPosition}px;
            overflow-y: scroll;
            width: 100%;`;
        }

        return () => {
            const scrollCur = appRef.current.style.top;
            appRef.current.style.cssText = "";
            window.scrollTo(0, parseInt(scrollCur || "0", 10) * -1);
        };
    }, [priceModalOpen, navModalOpen]);

    useEffect(() => {
        window.addEventListener("scroll", showScrollHandler);
        return () => {
            window.removeEventListener("scroll", showScrollHandler);
        };
    }, []);

    return (
        <div ref={appRef} className="landing">
            <Header openNavModal={openNavModal} />
            <div className="landing-body">
                <Outlet context={{ openPriceModal, openNavModal }} />
            </div>

            {showScroll && (
                <IcTopArrow
                    className="ic-top"
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                />
            )}
            <Footer />
            <FooterMobile />
            <NavModal onClose={closeNavModal} isOpen={navModalOpen} />
            <PriceModal onClose={closePriceModal} isOpen={priceModalOpen} />
        </div>
    );
}

export default Layout;
