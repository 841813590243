import { useRef, useEffect, useState } from "react";
import Banner from "../Banner/Banner";
import bg1Src from "../../../assets/images/ott/first-bg1.png";
import bg2Src from "../../../assets/images/ott/first-bg2.png";
import bg3Src from "../../../assets/images/ott/first-bg3.png";
import bg4Src from "../../../assets/images/ott/first-bg4.png";
import bg5Src from "../../../assets/images/ott/first-bg5.png";
import bg6Src from "../../../assets/images/ott/first-bg6.png";
import bg7Src from "../../../assets/images/ott/first-bg7.jpg";
import bg8Src from "../../../assets/images/ott/first-bg8.jpg";
import bg9Src from "../../../assets/images/ott/first-bg9.jpg";
import bg10Src from "../../../assets/images/ott/first-bg10.png";
import bg11Src from "../../../assets/images/ott/first-bg11.png";
import bg12Src from "../../../assets/images/ott/first-bg12.png";
import bg13Src from "../../../assets/images/ott/first-bg13.png";
import bg14Src from "../../../assets/images/ott/first-bg14.png";
import useLanguage from "../../../hooks/useLanguage";
import "./thirdpage.css";

const Banner1 = [
    { image: bg1Src },
    { image: bg2Src },
    { image: bg3Src },
    { image: bg4Src },
    { image: bg5Src },
    { image: bg6Src },
    { image: bg1Src },
    { image: bg2Src },
    { image: bg3Src },
    { image: bg4Src },
    { image: bg5Src },
    { image: bg6Src },
    { image: bg1Src },
    { image: bg2Src },
    { image: bg3Src },
    { image: bg4Src },
    { image: bg5Src },
    { image: bg6Src },
];

const Banner2 = [
    { image: bg7Src },
    { image: bg8Src },
    { image: bg9Src },
    { image: bg10Src },
    { image: bg11Src },
    { image: bg12Src },
    { image: bg7Src },
    { image: bg8Src },
    { image: bg9Src },
    { image: bg10Src },
    { image: bg11Src },
    { image: bg12Src },
    { image: bg7Src },
    { image: bg8Src },
    { image: bg9Src },
    { image: bg10Src },
    { image: bg11Src },
    { image: bg12Src },
];

const ThirdPage = () => {
    const { t, langState } = useLanguage();
    const thirdPageRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.5 }
        );
        observer.observe(thirdPageRef.current);
    }, []);

    return (
        <div className={`${show ? "show" : ""} third-page`} ref={thirdPageRef}>
            <div className="third-page-top">
                <p className={`third-page-title title ${langState}`}>{t("ott.third.title")}</p>
                <p className={`third-page-title-mobile title ${langState}`}>{t("ott.third.title-mobile")}</p>
                <p className="third-page-subtitle subtitle">{t("ott.third.subtitle-mobile")}</p>
                <p className="third-page-subtitle-mobile subtitle">{t("ott.third.subtitle-mobile")}</p>
            </div>
            <div className="third-slider">
                <Banner data={Banner1} speed={80000} className="third-slider-first" />
                <Banner data={Banner2} speed={80000} right />
            </div>
        </div>
    );
};

export default ThirdPage;
