import { useRef, useEffect, useState } from "react";
import { IcSecondBg } from "../../../constants/icons";
import SwiperImg from "../Swiper/Swiper";
import useLanguage from "../../../hooks/useLanguage";
import "./secondpage.css";

const SecondPage = () => {
    const { t, langState } = useLanguage();

    const secondPageRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.3 }
        );
        observer.observe(secondPageRef.current);
    }, []);

    return (
        <div className={`${show ? "show" : ""} stick-second-page`} ref={secondPageRef}>
            <div className="stick-second-page-top">
                <IcSecondBg />
                <p className={`stick-second-page-title ${langState}`}>{t("stick.second.title")}</p>
                <p className="stick-second-page-subtitle">{t("stick.second.subtitle")}</p>
            </div>

            <div className="stick-second-page-bottom">
                <SwiperImg />
            </div>
        </div>
    );
};

export default SecondPage;
