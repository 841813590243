import { useRef, useEffect, useState } from "react";
import {
    EventTitle1,
    EventTitle2,
    IcFifthBg,
    IcFourthBg,
    IcFourthBgCircle,
    IcFourthBgSnow,
    IcFourthComingSoon1,
    IcFourthComingSoon2,
    IcFourthLabel,
    IcPriceDiscount,
    IcSnowMobile,
} from "../../../constants/icons";
import fourthBgSrc from "../../../assets/images/stick/stick-fourth-bg.png";
import stickSrc from "../../../assets/images/stick/stick-fourth-stick.png";
import boxSrc from "../../../assets/images/stick/stick-fourth-box.png";
import useLanguage from "../../../hooks/useLanguage";
import "./fourthpage.css";

const FourthPage = () => {
    const { t, langState } = useLanguage();

    const fourthPageRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.3 }
        );
        observer.observe(fourthPageRef.current);
    }, []);

    return (
        <div className="stick-fourth-page-wrapper">
            <IcFifthBg className="stick-fifth-page-bg" />
            <IcFourthBgCircle className="stick-fourth-page-bg-circle" />
            <img src={fourthBgSrc} className="stick-fourth-page-bg" />
            {/* <IcFourthBg className="stick-fourth-page-bg" /> */}
            <div className={`${show ? "show" : ""} stick-fourth-page`} ref={fourthPageRef}>
                <p className={`stick-fourth-page-title ${langState}`}>{t("stick.fourth.title")}</p>
                <div className="stick-fourth-page-title-event">
                    {langState === "kor" ? (
                        <>
                            <EventTitle1 className="stick-fourth-page-title-event-first" />
                            <EventTitle2 className="stick-fourth-page-title-event-bg" />
                        </>
                    ) : (
                        <>
                            <IcFourthComingSoon1 className="stick-fourth-page-title-event-first comingsoon" />
                            <IcFourthComingSoon2 className="stick-fourth-page-title-event-bg comingsoon" />
                        </>
                    )}
                    <IcSnowMobile className="stick-fourth-page-price-snow-mobile" />
                </div>
                <p className="stick-fourth-page-subtitle">{t("stick.fourth.subtitle")}</p>
                <div className="stick-fourth-page-price">
                    <div>
                        <p className="stick-title">{t("stick.fourth.original")}</p>
                        <p className="stick-price">
                            350<span>USD</span>
                            <IcPriceDiscount />
                        </p>
                    </div>
                    <div className="stick-fourth-page-price-border" />
                    <div className="stick-discount">
                        <p className="stick-title">{t("stick.fourth.sale")}</p>
                        <p className="stick-price discount">
                            240<span>USD</span>
                        </p>
                    </div>
                    <IcFourthBgSnow className="stick-fourth-page-price-snow" />
                </div>

                <div className="stick-fifth-page-top">
                    <p className={`stick-fifth-page-title ${langState}`}>{t("stick.fifth.title")}</p>
                    <div className="stick-fifth-page-top-border" />
                    <p className="stick-fifth-page-subtitle">
                        {t("stick.fifth.body1")} / {t("stick.fifth.body2")} / {t("stick.fifth.body3")} / {t("stick.fifth.body4")}
                    </p>

                    <div className="stick-fifth-page-subtitle-mobile">
                        <p>{t("stick.fifth.body1")}</p>
                        <p>{t("stick.fifth.body2")}</p>
                        <p>{t("stick.fifth.body3")}</p>
                        <p>{t("stick.fifth.body4")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FourthPage;
