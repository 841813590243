import { useEffect, useRef, useState } from "react";
import { IcFourthTv, IcTv } from "../../../constants/icons";
import videoSrc from "../../../assets/images/ott/video.mp4";
import useLanguage from "../../../hooks/useLanguage";
import "./fourthpage.css";
import mobileTvInBox from "../../../assets/images/ott/mobileTvInBox.png";

const FourthPageMobile = () => {
    const { t, langState } = useLanguage();
    const fourthRef = useRef(null);

    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    } else if (!entry.isIntersecting) {
                        setShow(false);
                    }
                });
            },
            { threshold: 0.1 }
        );
        observer.observe(fourthRef.current);
    }, []);

    return (
        <div className="fourth-page-mobile" ref={fourthRef}>
            <div className="fourth-page-top">
                <p className={`fourth-page-title title ${langState}`}>{t("ott.fourth.title")}</p>
                <p className="fourth-page-subtitle subtitle">{t("ott.fourth.subtitle")}</p>
            </div>
            {/* {show && <video src={videoSrc} className="fourth-tv" muted autoPlay loop controls={false} playsInline />} */}
            <video src={videoSrc} className="fourth-tv" muted autoPlay loop controls={false} playsInline />
            {/* <IcFourthTv className="fourth-page-tv" /> */}
            <IcTv className="fourth-page-tv" />

            {/* <IcFourthBoxMobile className="fourth-page-box" /> */}
            <div className="fourth-page-box">
                <img src={mobileTvInBox} alt="Daily Art Story" />
            </div>
            {/* <div className="fourth-page-bottom">
                <div>
                    <p className="fourth-page-bottom-title">{t("ott.fourth.body1.title")}</p>
                    <p className="fourth-page-bottom-subtitle">{t("ott.fourth.body1.subtitle")}</p>
                </div>
                <div className="fourth-page-bottom-border" />
                <div>
                    <p className="fourth-page-bottom-title">{t("ott.fourth.body2.title")}</p>
                    <p className="fourth-page-bottom-subtitle">{t("ott.fourth.body2.subtitle")}</p>
                </div>
                <div className="fourth-page-bottom-border" />
                <div>
                    <p className="fourth-page-bottom-title">{t("ott.fourth.body3.title")}</p>
                    <p className="fourth-page-bottom-subtitle">{t("ott.fourth.body3.subtitle")}</p>
                </div>
            </div> */}
        </div>
    );
};

export default FourthPageMobile;
