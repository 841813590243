import React from "react";
import ReactDOM from "react-dom/client";
import { AppProvider } from "./context/appContext";
import { RecoilRoot } from "recoil";
import App from "./App";
import "./assets/css/global.css";
import "./translates/i18n";
import "./assets/css/common.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <RecoilRoot>
        <AppProvider>
            <App />
        </AppProvider>
    </RecoilRoot>
);
