import { useEffect, useRef, useState } from "react";
import "./event.css";
// import DailyArtStoryInviteImg from "../../assets/images/events/dailyartstory-invite.png";
import DailyArtStoryEventImg from "../../assets/images/events/dailyartstory-dasverse.png";

const DailyArtStoryInvite = () => {
    return (
        <div className="event-layout">
            <img src={DailyArtStoryEventImg} alt="invite-image" />
        </div>
    );
};

export default DailyArtStoryInvite;
