// import { IcLastArrowDown } from "../../constants/icons";
import { useState } from "react";
import { IcInsta, IcTwitter, IcYoutube } from "../../constants/icons";
import useLanguage from "../../hooks/useLanguage";
import "./footer.css";
import { INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL } from "../../constants/link";
import footerImg from "../../assets/images/footerIcon.png";
import { Link } from "react-router-dom";

const POLICY_LIST = {
    service: "footer.service",
    privacy: "footer.privacy",
    paid: "footer.paid-service",
    youth: "footer.youth-protect",
};

const FooterMobile = () => {
    const [curPolicy, setCurPolicy] = useState("service");
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useLanguage();

    return (
        <div className="footer-mobile">
            <div className="footer-mobile-bottom">
                <img src={footerImg} alt="footer-img" width="80px" />
                <div className="footer-mobile-bottom-info">
                    <p>1 Paya Lebar Link #06-08, PLQ2,</p>
                    <p>Paya Lebar Quarter, Singapore (408533)</p>
                    <span>contact@dasvers.com {"  |"}</span>
                    <span>(+65) 6381 6390</span>
                    <Link className="footer-link" to="https://www.dasuniverse.io">
                        www.dasuniverse.io
                    </Link>
                    {/* <p> {t("footer.dasverse")}</p>
                    <p>|</p>
                    <p>{t("footer.ceo")}</p>
                    <p>{t("footer.address")}</p>
                    <p>{t("footer.email")}</p>
                    <p>|</p>
                    <p>{t("footer.phone")}</p> */}
                </div>
                <span>© DAS UNIVERSE. All Rights Reserved.</span>
                {/* <p>{t("footer.rights")}</p> */}
            </div>
            {/* <div className="footer-bottom-sns">
                <IcTwitter
                    onClick={() => {
                        window.open(TWITTER_URL);
                    }}
                />
                <IcYoutube
                    onClick={() => {
                        window.open(YOUTUBE_URL);
                    }}
                />
                <IcInsta
                    onClick={() => {
                        window.open(INSTAGRAM_URL);
                    }}
                />
            </div> */}
            {/* <div
                className="footer-mobile-bottom-policy"
                onClick={() => {
                    setIsOpen((prev) => !prev);
                }}
            >
                <p>{t(POLICY_LIST[curPolicy])}</p>
                <IcLastArrowDown />
                {isOpen && (
                    <div className="footer-mobile-bottom-policy-select">
                        {Object.entries(POLICY_LIST).map((li) => (
                            <p
                                onClick={() => {
                                    setCurPolicy(li[0]);
                                }}
                            >
                                {t(li)}
                            </p>
                        ))}
                    </div>
                )}
            </div> */}
        </div>
    );
};

export default FooterMobile;
