import "./guidebox.css";

const GuideBox = (props) => {
    const { index, title, img, last } = props;
    return (
        <div className="guide-box">
            {img}
            <div className={`guide-box-text ${last ? "last" : ""}`}>
                <p>
                    <span>{index}.</span>
                    {title}
                </p>
            </div>
        </div>
    );
};

export default GuideBox;
