import { useEffect, useRef, useState } from "react";
import FifthPage from "../../components/OTT/FifthPage/FifthPage";
import FirstPage from "../../components/OTT/FirstPage/FirstPage";
import FourthPage from "../../components/OTT/FourthPage/FourthPage";
import FourthPageMobile from "../../components/OTT/FourthPage/FourthPageMobile";
import LastPage from "../../components/OTT/LastPage/LastPage";
import PriceModal from "../../components/OTT/Modals/PriceModal";
import SecondPage from "../../components/OTT/SecondPage/SecondPage";
import ThirdPage from "../../components/OTT/ThirdPage/ThirdPage";
import "./ott.css";
import { useOutletContext } from "react-router-dom";
import { useRecoilState } from "recoil";
import { positionAtomState } from "../../stores/atom";
function OTT() {
    const { openPriceModal } = useOutletContext();
    const [position, setPositionState] = useRecoilState(positionAtomState);

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, isStick: false, isDisplay: { flag: false } };
        });
    }, []);

    return (
        <>
            <FirstPage />
            <SecondPage />
            <ThirdPage />
            <FourthPage />
            <FourthPageMobile />
            {/* <FifthPage /> */}
            <LastPage openModal={openPriceModal} />
        </>
    );
}

export default OTT;
