import { useRef, useEffect, useState } from "react";
import useLanguage from "../../../hooks/useLanguage";
import "./lastpage.css";

const LastPage = () => {
    const { t, langState } = useLanguage();

    const lastPageRef = useRef(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.5 }
        );
        observer.observe(lastPageRef.current);
    }, []);

    return (
        <div className={`${show ? "show" : ""} stick-last-page`} ref={lastPageRef}>
            <p className={`stick-last-page-title ${langState}`}>{t("stick.last.title")}</p>
            <p className={`stick-last-page-title-mobile ${langState}`}>{t("stick.last.title")}</p>
            <p className="stick-last-page-subtitle">{t("stick.last.subtitle")}.</p>
            <p className="stick-last-page-subtitle-mobile">{t("stick.last.subtitle-mobile")}</p>
            <button className="stick-last-page-btn">{t("stick.last.btn")}</button>
        </div>
    );
};

export default LastPage;
