import useLanguage from "../../../hooks/useLanguage";
import secondImg1Src from "../../../assets/images/display/display-second-1.png";
import secondImg2Src from "../../../assets/images/display/display-second-2.png";
import secondImg2EngSrc from "../../../assets/images/display/display-second-2-eng.png";
import secondImg3Src from "../../../assets/images/display/display-second-3.png";
import secondImg3EngSrc from "../../../assets/images/display/display-second-3-eng.png";
import "./secondpage.css";

const SECOND_LIST = [
    {
        title: "display.second.item1.title",
        mobile_title: "display.second.item1.mobile_title",
        subtitle: "display.second.item1.subtitle",
        mobile_subtitle: "display.second.item1.mobile_subtitle",
        img: secondImg1Src,
        eng_img: secondImg1Src,
    },
    {
        title: "display.second.item2.title",
        mobile_title: "display.second.item2.mobile_title",
        subtitle: "display.second.item2.subtitle",
        mobile_subtitle: "display.second.item2.mobile_subtitle",
        img: secondImg2Src,
        eng_img: secondImg2EngSrc,
    },
    {
        title: "display.second.item3.title",
        mobile_title: "display.second.item3.mobile_title",
        subtitle: "display.second.item3.subtitle",
        mobile_subtitle: "display.second.item3.mobile_subtitle",
        img: secondImg3Src,
        eng_img: secondImg3EngSrc,
    },
];

const SecondPage = () => {
    const { t, langState } = useLanguage();

    return (
        <div className="display-second">
            {SECOND_LIST.map((li, idx) => (
                <div className={`display-second-item ${idx}`} key={idx}>
                    <p className={`display-title web ${langState}`}>{t(li.title)}</p>
                    <p className="display-title mobile">{t(li.mobile_title)}</p>
                    <p className="display-subtitle web">{t(li.subtitle)}</p>
                    <p className="display-subtitle mobile">{t(li.mobile_subtitle)}</p>
                    {langState === "kor" ? <img src={li.img} /> : <img src={li.eng_img} />}
                </div>
            ))}
        </div>
    );
};

export default SecondPage;
