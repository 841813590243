import "./banner.css";

const Banner = (props) => {
    const { data, speed, right, className } = props;

    return (
        <>
            <div className={`banner ${className || ""}`}>
                <div className={`banner-wrapper ${right ? "right" : ""}`}>
                    <section style={{ "--speed": `${speed}ms` }}>
                        {data?.map((img, idx) => (
                            <div className="banner-image" key={idx}>
                                <img src={img.image} alt={idx} />
                            </div>
                        ))}
                    </section>
                    <section style={{ "--speed": `${speed}ms` }}>
                        {data?.map((img, idx) => (
                            <div className="banner-image" key={idx}>
                                <img src={img.image} alt={idx} />
                            </div>
                        ))}
                    </section>
                    <section style={{ "--speed": `${speed}ms` }}>
                        {data?.map((img, idx) => (
                            <div className="banner-image" key={idx}>
                                <img src={img.image} alt={idx} />
                            </div>
                        ))}
                    </section>
                </div>
            </div>
            <div className="banner-mobile">
                {data?.map((img, idx) => (
                    <div key={`${img.image}-${idx}`} className="banner-mobile-item">
                        <img src={img.image} alt={idx} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default Banner;
