import { useRef, useEffect, useState } from "react";
import {
    IcBuy1,
    IcBuy2,
    IcBuy3,
    IcBuy4,
    IcBuy5,
    IcBuy6,
    IcBuyBorder,
    IcBuyBorderMobile,
    IcLastBgLeft,
    IcLastBgMiddle,
    IcLastMiddleLeft,
    IcLastMiddleRight,
    IcTabArrow,
} from "../../../constants/icons";
import useLanguage from "../../../hooks/useLanguage";
import "./lastpage.css";
import monthSubEngSrc from "../../../assets/images/ott/month-sub-eng.svg";
import monthSubSrc from "../../../assets/images/ott/month-sub.svg";
import yearSubEngSrc from "../../../assets/images/ott/year-sub-eng.svg";
import yearSubSrc from "../../../assets/images/ott/year-sub.svg";

import { useRecoilState } from "recoil";
import { loginAtomState, positionAtomState } from "../../../stores/atom";
import { join } from "../../../utils/join";

const LastPage = (props) => {
    const { openModal } = props;
    const [isBodyOpen, setIsBodyOpen] = useState({});
    const { t, langState } = useLanguage();

    const lastPageRef = useRef(null);
    const [show, setShow] = useState(false);
    const [position, setPositionState] = useRecoilState(positionAtomState);
    const [loginState, setLoginState] = useRecoilState(loginAtomState);

    const getPosition = () => {
        setPositionState((prev) => {
            return { ...prev, END: lastPageRef.current.offsetTop };
        });
    };

    useEffect(() => {
        //for yPosition
        setPositionState((prev) => {
            return { ...prev, END: lastPageRef.current.offsetTop - 1 };
        });
        window.addEventListener("resize", getPosition);

        //for interaction
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((entry) => {
                    if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                        setShow(true);
                    }
                });
            },
            { threshold: 0.3 }
        );
        observer.observe(lastPageRef.current);

        return () => {
            window.removeEventListener("resize", getPosition);
        };
    }, []);

    return (
        <div className={`${show ? "show" : ""} last-page`} ref={lastPageRef}>
            <IcLastBgLeft className="last-page-left-bg" />
            <div className="last-page-top">
                <div className={`last-page-title ${langState}`}>
                    <div className="last-page-title-shadow" />
                    <p className="title-green">{t("ott.last.title-green")}</p>
                    {/* <p className="last-title">{t("ott.last.title")}</p>
                    <p className="last-title-mobile">{t("ott.last.title-mobile")}</p> */}
                </div>
                {/* <p className="last-page-subtitle">
                    {t("ott.last.subtitle.subtitle1")}
                    <span className="price">{t("ott.last.subtitle.subtitle2")}</span>
                    {t("ott.last.subtitle.subtitle3")} <span className="last">{t("ott.last.subtitle.subtitle4")}</span>
                </p>
                <p className="last-page-subtitle-mobile">
                    {t("ott.last.subtitle-mobile.subtitle1")}
                    <span className="price">{t("ott.last.subtitle.subtitle2")}</span>
                    {t("ott.last.subtitle.subtitle3")} <span>{t("ott.last.subtitle.subtitle4")}</span>
                </p>
                <p className="last-page-subtitle-sub">
                    <span>&#183; </span>
                    {t("ott.last.subtitle-sub")}
                </p> */}
            </div>
            <div className="last-page-middle">
                <IcLastBgMiddle />

                <div className="last-page-middle-sub-img">
                    <img src={langState === "kor" ? monthSubSrc : monthSubEngSrc} />
                    <img src={langState === "kor" ? yearSubSrc : yearSubEngSrc} />
                </div>

                {/* for ces
                 <div className="last-page-middle-svg">
                    <IcLastMiddleLeft className="first" />
                    <IcLastMiddleRight className="second" />
                </div>

                <div className={`last-page-middle-title ${langState}`}>
                    <p>
                        {t("ott.last.middle-title.first")} <span>{t("ott.last.middle-title-span")}</span>
                    </p>
                    <p>
                        {t("ott.last.middle-title.ces")} <span>{t("ott.last.middle-title-span")}</span>
                    </p>
                </div> */}

                <button
                    className="item-hidden"
                    // className={`last-page-middle-btn ${loginState ? "" : "display-none"}`}
                    onClick={() => {
                        join(langState);
                    }}
                >
                    {t("ott.last.btn")}
                </button>
                {/* <p className="last-page-middle-info" onClick={openModal}>
                    {t("ott.last.price-title")}
                </p> */}
            </div>

            <div className="last-page-bottom">
                <p className={`last-page-bottom-title ${langState}`}> {t("ott.last.faq-title")}</p>
                <div className="last-page-bottom-tab">
                    <div>
                        <div
                            className={`last-page-bottom-tab-title ${isBodyOpen[0] ? "active" : ""}`}
                            onClick={() => {
                                setIsBodyOpen({ 0: !isBodyOpen[0] });
                            }}
                        >
                            <p>{t("ott.last.faq-body1.title")}</p>
                            <IcTabArrow />
                        </div>
                        {isBodyOpen[0] && (
                            <div className="last-page-bottom-tab-body">
                                <p>{t("ott.last.faq-body1.body")}</p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            className={`last-page-bottom-tab-title ${isBodyOpen[1] ? "active" : ""}`}
                            onClick={() => {
                                setIsBodyOpen({ 1: !isBodyOpen[1] });
                            }}
                        >
                            <p>{t("ott.last.faq-body2.title")}</p>
                            <IcTabArrow />
                        </div>
                        {isBodyOpen[1] && (
                            <div className="last-page-bottom-tab-body">
                                <p>{t("ott.last.faq-body2.body")}</p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            className={`last-page-bottom-tab-title ${isBodyOpen[2] ? "active" : ""}`}
                            onClick={() => {
                                setIsBodyOpen({ 2: !isBodyOpen[2] });
                            }}
                        >
                            <p>{t("ott.last.faq-body3.title")}</p>
                            <IcTabArrow />
                        </div>
                        {isBodyOpen[2] && (
                            <div className="last-page-bottom-tab-body">
                                <p>{t("ott.last.faq-body3.body")}</p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            className={`last-page-bottom-tab-title ${isBodyOpen[3] ? "active" : ""}`}
                            onClick={() => {
                                setIsBodyOpen({ 3: !isBodyOpen[3] });
                            }}
                        >
                            <p>{t("ott.last.faq-body4.title")}</p>
                            <IcTabArrow />
                        </div>
                        {isBodyOpen[3] && (
                            <div className="last-page-bottom-tab-body">
                                <p>{t("ott.last.faq-body4.body")}</p>

                                <div className="last-page-bottom-tab-border">
                                    <div className={`last-page-bottom-buy ${langState}`}>
                                        <div className={"last-page-bottom-buy-item"}>
                                            <IcBuy1 />
                                            <p className="web">{t("ott.last.faq-body4.card1.web")}</p>
                                            <p className="mobile">{t("ott.last.faq-body4.card1.mobile")}</p>
                                        </div>
                                        <IcTabArrow />
                                        <div className="last-page-bottom-buy-item">
                                            <IcBuy2 />
                                            <p className="web">{t("ott.last.faq-body4.card2.web")}</p>
                                            <p className="mobile">{t("ott.last.faq-body4.card2.mobile")}</p>
                                        </div>
                                        <IcTabArrow />
                                        <div className="last-page-bottom-buy-item">
                                            <IcBuy3 />
                                            <p className="web">{t("ott.last.faq-body4.card3.web")}</p>
                                            <p className="mobile">{t("ott.last.faq-body4.card3.mobile")}</p>
                                        </div>
                                        <IcTabArrow />
                                        <div className="last-page-bottom-buy-item">
                                            <IcBuy4 />
                                            <p className="green">{t("ott.last.faq-body4.card4.option")}</p>
                                            <p className="web">{t("ott.last.faq-body4.card4.web")}</p>
                                            <p className="mobile">{t("ott.last.faq-body4.card4.mobile")}</p>
                                        </div>
                                        <IcTabArrow />
                                        <div className="last-page-bottom-buy-item">
                                            <IcBuy5 />
                                            <p className="web">{t("ott.last.faq-body4.card5.web")}</p>
                                            <p className="mobile">{t("ott.last.faq-body4.card5.mobile")}</p>
                                        </div>
                                        <IcTabArrow />
                                        <div className="last-page-bottom-buy-item last">
                                            <IcBuy6 />
                                            <IcBuyBorder className="last-border" />
                                            <IcBuyBorderMobile className="last-border-mobile" />
                                            <p className="web">{t("ott.last.faq-body4.card6.web")}</p>
                                            <p className="mobile">{t("ott.last.faq-body4.card6.mobile")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            className={`last-page-bottom-tab-title ${isBodyOpen[4] ? "active" : ""}`}
                            onClick={() => {
                                setIsBodyOpen({ 4: !isBodyOpen[4] });
                            }}
                        >
                            <p>{t("ott.last.faq-body5.title")}</p>
                            <IcTabArrow />
                        </div>
                        {isBodyOpen[4] && (
                            <div className="last-page-bottom-tab-body">
                                <p>
                                    {t("ott.last.faq-body5.body1")} <span>{t("ott.last.faq-body5.body3")}</span>
                                </p>

                                <div
                                    className="item-hidden"
                                    // className="last-page-bottom-tab-body-li"
                                >
                                    <p>{t("ott.last.faq-body5.li1")}</p>
                                    <p>{t("ott.last.faq-body5.li2")}</p>
                                    <p>{t("ott.last.faq-body5.li3")}</p>
                                </div>
                                <p className="item-hidden">
                                    {t("ott.last.faq-body5.body2")}
                                    {t("ott.last.faq-body5.body4")}
                                </p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div
                            className={`last-page-bottom-tab-title ${isBodyOpen[5] ? "active" : ""}`}
                            onClick={() => {
                                setIsBodyOpen({ 5: !isBodyOpen[5] });
                            }}
                        >
                            <p>{t("ott.last.faq-body6.title")}</p>
                            <IcTabArrow />
                        </div>
                        {isBodyOpen[5] && (
                            <div className="last-page-bottom-tab-body">
                                <p>
                                    {t("ott.last.faq-body6.body1")}
                                    <span>{t("ott.last.faq-body6.body2")}</span>
                                    {t("ott.last.faq-body6.body3")}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LastPage;
