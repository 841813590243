import Modal from "react-modal";
import "./modal.css";
import { IcDelete, IcHeaderMobileLogo, IcTabArrow } from "../../../constants/icons";
import useLanguage from "../../../hooks/useLanguage";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { LANG } from "../../../constants/utils";
import { loginAtomState } from "../../../stores/atom";
import { useRecoilState } from "recoil";
import { join } from "../../../utils/join";

const customStyles = {
    content: {
        top: "0",
        left: "0",
        padding: "0",
        width: "max-content",
        border: "none",
        background: "none",
        minHeight: "max-content",
    },
};

const NavModal = (props) => {
    const { isOpen, onClose } = props;
    const { t, langState, changeLanguage } = useLanguage();
    const [showLang, setShowLang] = useState(false);
    const [curLang, setCurLang] = useState("");

    const [loginState, setLoginState] = useRecoilState(loginAtomState);

    useEffect(() => {
        const _curLang = LANG.filter((li) => li.code === langState);
        setCurLang(_curLang[0]?.title);
    }, [langState]);

    return (
        <Modal {...props} shouldCloseOnOverlayClick={false} style={customStyles} ariaHideApp={false} isOpen={isOpen}>
            <div className="nav-modal">
                <div className="nav-modal-header">
                    <IcHeaderMobileLogo />
                    <IcDelete onClick={onClose} />
                </div>
                <div className="nav-modal-body">
                    {loginState && (
                        <a
                            onClick={() => {
                                join(langState);
                            }}
                            className="item-hidden"
                        >
                            {t("header.join")}
                        </a>
                    )}
                    <NavLink to="/" className={({ isActive }) => (isActive ? "active" : "")} onClick={onClose}>
                        Daily Art Story
                    </NavLink>
                    <Link to="https://www.dasuniverse.io" className={({ isActive }) => (isActive ? "active" : "")}>
                        {t("header.stick")}
                    </Link>
                    {/* 기존 */}
                    {/* <NavLink to="stick" className={({ isActive }) => (isActive ? "active" : "")} onClick={onClose}>
                        {t("header.stick")}
                    </NavLink> */}
                    <NavLink
                        to="display"
                        className="item-hidden"
                        // className={({ isActive }) => (isActive ? "active" : "")}
                        onClick={onClose}
                    >
                        {t("header.display")}
                    </NavLink>
                    <div className="nav-modal-border" />
                </div>
                <div className="nav-modal-lang">
                    <p className="nav-modal-lang-title">{t("header.language")}</p>
                    <div>
                        <div
                            className={`nav-modal-lang-cur ${showLang ? "show" : ""}`}
                            onClick={() => {
                                setShowLang((prev) => !prev);
                            }}
                        >
                            <p>{curLang}</p>
                            <IcTabArrow />
                        </div>
                        {showLang && (
                            <div className="nav-modal-lang-select">
                                {LANG.map((li) => (
                                    <p
                                        key={li.code}
                                        className={langState === li.code ? "active" : ""}
                                        onClick={() => {
                                            changeLanguage(li.code);
                                            setShowLang(false);
                                        }}
                                    >
                                        {li.title}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default NavModal;
