import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { langAtomState } from "../stores/atom";
// import axios from "axios";

const lang = localStorage.getItem("lang");

const AppContext = React.createContext(null);

const AppProvider = ({ children }) => {
    const [langState, setLangState] = useRecoilState(langAtomState);
    const { i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLangState(lang);
        localStorage.setItem("lang", lang);
    };

    const initSettingState = () => {
        const params = new URLSearchParams(window.location.search);
        const langParms = params.get("lang");
        changeLanguage(langParms || lang || process.env.REACT_APP_LANG);
    };

    useEffect(() => {
        initSettingState();
    }, []);

    const returnValue = {
        changeLanguage,
    };

    return <AppContext.Provider value={returnValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
    return useContext(AppContext);
};

export { AppProvider, useAppContext };
