import useLanguage from "../../../hooks/useLanguage";
import thirdImg1Src from "../../../assets/images/display/display-third-1.png";
import thirdImg2Src from "../../../assets/images/display/display-third-2.png";
import thirdImg3Src from "../../../assets/images/display/display-third-3.png";
import { useEffect, useRef } from "react";
import { positionAtomState } from "../../../stores/atom";
import { useRecoilState } from "recoil";
import "./thirdpage.css";

const THIRD_LIST = [
    {
        title: "display.third.color.color1",
        img: thirdImg1Src,
    },
    {
        title: "display.third.color.color2",
        img: thirdImg2Src,
    },
    {
        title: "display.third.color.color3",
        img: thirdImg3Src,
    },
];

const ThirdPage = () => {
    const thirdRef = useRef();
    const [position, setPositionState] = useRecoilState(positionAtomState);
    const { t, langState } = useLanguage();

    const getPosition = () => {
        setPositionState((prev) => {
            return { ...prev, isDisplay: { ...prev.isDisplay, firstStart: thirdRef.current.offsetTop - 1 } };
        });
    };

    useEffect(() => {
        setPositionState((prev) => {
            return { ...prev, isDisplay: { ...prev.isDisplay, firstStart: thirdRef.current.offsetTop - 1 } };
        });
        window.addEventListener("resize", getPosition);
        return () => {
            window.removeEventListener("resize", getPosition);
        };
    }, []);

    return (
        <div className="display-third" ref={thirdRef}>
            <div className="display-third-top">
                <p className={`display-third-title display-title web ${langState}`}>{t("display.third.title")}</p>
                <p className={`display-third-title display-title mobile ${langState}`}>{t("display.third.mobile_title")}</p>
                <p className="display-third-title-subtitle display-subtitle web">
                    {t("display.third.subtitle.subtitle1")}
                    <span>{t("display.third.subtitle.subtitle2")}</span>
                    {t("display.third.subtitle.subtitle3")}
                    <span>{t("display.third.subtitle.subtitle4")}</span>
                    {t("display.third.subtitle.subtitle5")}
                    <span>{t("display.third.subtitle.subtitle6")}</span>
                    {t("display.third.subtitle.subtitle7")}
                </p>
                <p className="display-third-title-subtitle display-subtitle mobile">
                    {t("display.third.subtitle-mobile.subtitle1")}
                    <span>{t("display.third.subtitle-mobile.subtitle2")}</span>
                    {t("display.third.subtitle-mobile.subtitle3")}
                    <span>{t("display.third.subtitle-mobile.subtitle4")}</span>
                    {t("display.third.subtitle-mobile.subtitle5")}
                    <span>{t("display.third.subtitle-mobile.subtitle6")}</span>
                    {t("display.third.subtitle-mobile.subtitle7")}
                </p>
            </div>
            <div className="display-third-bottom">
                {THIRD_LIST.map((li, idx) => (
                    <div className={`display-third-item ${idx}`} key={idx}>
                        <p className="display-third-item-title">{t(li.title)}</p>
                        <img src={li.img} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ThirdPage;
