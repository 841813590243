import { IcFirstBgCircle1, IcFirstBgCircle2, IcFirstBgGreen, IcFirstBgYellow } from "../../../constants/icons";
import stickSrc from "../../../assets/images/stick/stick-img.png";
import useLanguage from "../../../hooks/useLanguage";
import "./firstpage.css";

const FirstPage = () => {
    const { t, langState } = useLanguage();

    return (
        <div className="stick-first-page">
            <div className="stick-first-page-left">
                <IcFirstBgCircle2 className="stick-first-page-bg-circle" />
                <p className={`stick-first-page-title ${langState}`}>{t("stick.first.title")}</p>
                <p className="stick-first-page-subtitle">{t("stick.first.subtitle")}</p>
                <p className="stick-first-page-subtitle-mobile">{t("stick.first.subtitle-mobile")}</p>
            </div>
            <IcFirstBgCircle1 className="stick-first-page-bg-circle-top" />
            <div className="stick-first-page-right">
                <img src={stickSrc} />
                <IcFirstBgGreen className="stick-first-page-bg-green" />
                <IcFirstBgYellow className="stick-first-page-bg-yellow" />
            </div>
        </div>
    );
};

export default FirstPage;
