import lastImgSrc from "../../../assets/images/display/display-last-img.png";
import lastImgEngSrc from "../../../assets/images/display/display-last-img-eng.png";
import lastImgMobileSrc from "../../../assets/images/display/display-last-img-mobile.png";
import lastImgMobileEngSrc from "../../../assets/images/display/display-last-img-mobile-eng.png";
import lastTextSrc from "../../../assets/images/display/text/display-last-text.png";
import lastTextEngSrc from "../../../assets/images/display/text/display-last-text-eng.png";
import lastTextMobile1Src from "../../../assets/images/display/text/display-last-text-mobile1.png";
import lastTextMobile1EngSrc from "../../../assets/images/display/text/display-last-text-mobile1-eng.png";
import lastTextMobile2Src from "../../../assets/images/display/text/display-last-text-mobile2.png";
import lastTextMobile3Src from "../../../assets/images/display/text/display-last-text-mobile3.png";
import lastTextMobile4Src from "../../../assets/images/display/text/display-last-text-mobile4.png";
import lastTextMobile5Src from "../../../assets/images/display/text/display-last-text-mobile5.png";
import lastTextMobile6Src from "../../../assets/images/display/text/display-last-text-mobile6.png";
import lastTextMobile7Src from "../../../assets/images/display/text/display-last-text-mobile7.png";
import lastTextMobile8Src from "../../../assets/images/display/text/display-last-text-mobile8.png";
import lastTextMobile9Src from "../../../assets/images/display/text/display-last-text-mobile9.png";
import lastTextMobile2EngSrc from "../../../assets/images/display/text/display-last-text-mobile2-eng.png";
import lastTextMobile3EngSrc from "../../../assets/images/display/text/display-last-text-mobile3-eng.png";
import lastTextMobile4EngSrc from "../../../assets/images/display/text/display-last-text-mobile4-eng.png";
import lastTextMobile5EngSrc from "../../../assets/images/display/text/display-last-text-mobile5-eng.png";
import lastTextMobile6EngSrc from "../../../assets/images/display/text/display-last-text-mobile6-eng.png";
import lastTextMobile7EngSrc from "../../../assets/images/display/text/display-last-text-mobile7-eng.png";
import lastTextMobile8EngSrc from "../../../assets/images/display/text/display-last-text-mobile8-eng.png";
import lastTextMobile9EngSrc from "../../../assets/images/display/text/display-last-text-mobile9-eng.png";
import "./lastpage.css";
import { useState } from "react";
import useLanguage from "../../../hooks/useLanguage";

const LastPage = () => {
    const [showMore, setShowMore] = useState(false);
    const { t, langState } = useLanguage();
    return (
        <div className="display-last">
            <p className={`display-last-title display-title ${langState}`}>{t("display.last.title")}</p>
            {langState === "kor" ? (
                <>
                    <img src={lastImgSrc} className="display-last-img1 web" alt="web-tv" />
                    <img src={lastImgMobileSrc} className="display-last-img1 mobile" alt="mobile-tv" />

                    <img src={lastTextSrc} className="display-last-img2 web" alt="web-text-2-" />
                    <img src={lastTextMobile1Src} className="display-last-img2 mobile" alt="mobile-text-2" />
                </>
            ) : (
                <>
                    <img src={lastImgEngSrc} className="display-last-img1 web" alt="web-tv-eng" />
                    <img src={lastImgMobileEngSrc} className="display-last-img1 mobile" alt="mobile-tv-eng" />

                    <img src={lastTextEngSrc} className="display-last-img2 web" alt="web-text-2-eng" />
                    <img src={lastTextMobile1EngSrc} className="display-last-img2 mobile" alt="mobile-text-2-eng" />
                </>
            )}

            {showMore ? (
                <>
                    {langState === "kor" ? (
                        <>
                            <img src={lastTextMobile2Src} className="display-last-img2 mobile" alt="mobile-text-2" />
                            <img src={lastTextMobile3Src} className="display-last-img2 mobile" alt="mobile-text-3" />
                            <img src={lastTextMobile4Src} className="display-last-img2 mobile" alt="mobile-text-4" />
                            <img src={lastTextMobile5Src} className="display-last-img2 mobile" alt="mobile-text-5" />
                            <img src={lastTextMobile6Src} className="display-last-img2 mobile" alt="mobile-text-6" />
                            <img src={lastTextMobile7Src} className="display-last-img2 mobile" alt="mobile-text-7" />
                            <img src={lastTextMobile8Src} className="display-last-img2 mobile" alt="mobile-text-8" />
                            <img src={lastTextMobile9Src} className="display-last-img2 mobile" alt="mobile-text-9" />
                        </>
                    ) : (
                        <>
                            <img src={lastTextMobile2EngSrc} className="display-last-img2 mobile" alt="mobile-text-2-eng" />
                            <img src={lastTextMobile3EngSrc} className="display-last-img2 mobile" alt="mobile-text-3-eng" />
                            <img src={lastTextMobile4EngSrc} className="display-last-img2 mobile" alt="mobile-text-4-eng" />
                            <img src={lastTextMobile5EngSrc} className="display-last-img2 mobile" alt="mobile-text-5-eng" />
                            <img src={lastTextMobile6EngSrc} className="display-last-img2 mobile" alt="mobile-text-6-eng" />
                            <img src={lastTextMobile7EngSrc} className="display-last-img2 mobile" alt="mobile-text-7-eng" />
                            <img src={lastTextMobile8EngSrc} className="display-last-img2 mobile" alt="mobile-text-8-eng" />
                            <img src={lastTextMobile9EngSrc} className="display-last-img2 mobile" alt="mobile-text-9-eng" />
                        </>
                    )}
                    <button
                        className="display-last-btn mobile"
                        onClick={() => {
                            setShowMore((prev) => !prev);
                        }}
                    >
                        {t("display.last.btn-fold")}
                    </button>
                </>
            ) : (
                <button
                    className="display-last-btn mobile"
                    onClick={() => {
                        setShowMore((prev) => !prev);
                    }}
                >
                    {t("display.last.btn-open")}
                </button>
            )}
        </div>
    );
};

export default LastPage;
