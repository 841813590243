// atom.js
import { atom } from "recoil";

const lang = localStorage.getItem("lang");

export const langAtomState = atom({
    key: "language",
    default: lang || process.env.REACT_APP_LANG,
});

export const positionAtomState = atom({
    key: "yPosition",
    default: {
        START: 0,
        END: 0,
        isStick: false,
        isDisplay: {
            flag: false,
            firstStart: 0,
            firstEnd: 0,
            secondStart: 0,
        },
    },
});

export const loginAtomState = atom({
    key: "loginShow",
    default: true,
});
