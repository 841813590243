import Modal from "react-modal";
import "./modal.css";
import { IcDelete } from "../../../constants/icons";
import useLanguage from "../../../hooks/useLanguage";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        padding: "0",
        width: "max-content",
        border: "none",
        background: "none",
        minHeight: "max-content",
    },
};

const PriceModal = (props) => {
    const { isOpen, onClose } = props;
    const { t, langState } = useLanguage();

    return (
        <Modal {...props} shouldCloseOnOverlayClick={false} style={customStyles} ariaHideApp={false} isOpen={isOpen}>
            <div className="price-modal">
                <IcDelete onClick={onClose} />
                <div className="price-table">
                    <div className="price-first-row">
                        <div />
                        <div>
                            <p>{t("ott.priceModal.month")}</p>
                        </div>
                        <div>
                            <p>{t("ott.priceModal.year")}</p>
                        </div>
                    </div>
                    <div className="price-second-row">
                        <div />
                        <div>
                            <p>{t("ott.priceModal.type")}</p>
                        </div>
                    </div>
                    <div className="price-third-row">
                        <div>
                            <p className="row-title">{t("ott.priceModal.fee")}</p>
                        </div>
                        <div>
                            <p>
                                {"USD 20 \n"}
                                <span>{t("ott.priceModal.month-fee")}</span>
                            </p>
                        </div>
                        <div>
                            <p>
                                {"USD 200 \n"} <span>{t("ott.priceModal.year-fee")}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PriceModal;
